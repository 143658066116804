@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background: rgb(230, 230, 230);
}

@layer base {
  html {
    font-size: 15.5px; /* or use @apply text-sm; */
  }
}